<template>
  <v-navigation-drawer
    right
    v-model="isOpen"
    fixed temporary
    :width="$vuetify.breakpoint.xs ? 350 : 500"
    :permanent="loading"
  >
    <!-- TOP -->
    <template v-slot:prepend>
      <v-toolbar color="white" tile dense class="top">
        <v-img
          v-if="sb"
          height="40px"
          style="max-width: 95px"
          contain
          :src="helpers.logos[sb].url"
        ></v-img>
        <v-toolbar-title></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="grey"
          text icon
          small
          @click.stop="cancel"
        >
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
    </template>

    <!-- BOTTOM -->
    <template v-slot:append>
      <div v-if="user && user.vbl" class="text-center">
        <v-btn color="success" @click.stop="override">vbl override</v-btn>
      </div>
      <v-btn
        v-if="helpers.lookup[sb]"
        color="color3"
        text
        class="xsmall"
        :href="helpers.lookup[sb]"
        target="_blank"
        block
      >
        {{ helpers.lookupLabel[sb] }}
        <v-icon class="ml-4" small>fas fa-external-link-alt</v-icon>
      </v-btn>
      <v-divider></v-divider>
      <v-card-actions class="justify-center">
        <v-row dense>
          <v-col cols="6">
            <v-btn
              color="success"
              @click.stop="onActionClick"
              :loading="loading"
              block class="py-6"
              :disabled="!(valid || verifiable)"
            >{{ actionText }}</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              color="error"
              @click.stop="cancel"
              :disabled="loading"
              block class="py-6"
            >Cancel</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </template>

    <!-- BODY -->
    <v-card-text v-if="sb" :class="{ 'px-0': window === 1 }">
      <v-container class="px-0">
        <v-row dense>
          <v-col cols="12" class="text-h6">
            {{ body }} Membership Verification
          </v-col>
        </v-row>
        <v-window v-model="window">
          <v-window-item :key="0">
            <v-row dense>
              <v-col class="text-center" cols="12">
                <v-text-field
                  :label="`${body} Number*`"
                  v-model="number"
                  persistent-hint
                  :rules="dirty ? [
                    () => !!number || `${body} number is required`
                  ] : []"
                  @change="dirty = true"
                  color="color3"
                  type="search"
                  :hint="helpers.hint[sb] || ''"
                >
                </v-text-field>
              </v-col>
              <v-col class="text-center" cols="12">
                <v-dialog
                  ref="dobPicker"
                  v-model="dobPicker"
                  width="290px"
                  :return-value.sync="player.dob"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      label="Birthdate*"
                      readonly
                      v-model="player.dobF"
                      persistent-hint
                      :hint="`What is ${player.firstName || playerString}'s birthdate?`"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="dobPickerPicker"
                    v-model="player.dob"
                    :color="chipColor"
                    @change="$refs.dobPicker.save(player.dob)"
                  ></v-date-picker>
                </v-dialog>
              </v-col>
              <v-col class="text-center" cols="12">
                <v-alert type="success" v-if="status && status === 'true'" prominent text>
                  You are all set!
                </v-alert>
                <v-alert type="warning" v-if="status && status === 'expired'" prominent text>
                  This membership is expired
                </v-alert>
                <v-alert type="error" v-if="status && status === 'false'" prominent text>
                  {{ this.manualError || 'Invalid Membership' }}
                </v-alert>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item :key="1">
          </v-window-item>
        </v-window>
      </v-container>
    </v-card-text>
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar color="color1 color1Text--text">
          <v-toolbar-title>🤔 Hmmm!</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row dense>
            <v-col cols="12" class="title">
              We are a bit confused!
              <div class="subheading mt-1">According to our records, the number you entered belongs to a different player.</div>
            </v-col>
          </v-row>
          <v-list>

            <v-list-item>
              <v-subheader class="pa-0">You are registering</v-subheader>
            </v-list-item>

            <v-list-item>
              <v-list-item-action v-if="!$vuetify.breakpoint.xsOnly"></v-list-item-action>
              <v-list-item-content v-if="theOg">
                <v-list-item-title>
                  {{theOg.fullName}} {{theOg.ageGroup && theOg.ageGroup > 18 ? '' : `${theOg.ageGroup}U`}}
                  <span class="grey--text text--lighten-1 caption">
                    {{theOg.email ? ` - ${theOg.email}` : ''}}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle>{{theOg.cityState}}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content v-else>
                <v-list-item-title>
                  {{player.fullname}}
                  <span class="grey--text text--lighten-1 caption">
                    {{player.email ? ` - ${player.email}` : ''}}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-subheader class="pa-0">The number seems to belong to</v-subheader>
            </v-list-item>

            <v-list-item>
              <v-list-item-action v-if="!$vuetify.breakpoint.xsOnly"></v-list-item-action>
              <v-list-item-content v-if="theNoob">
                <v-list-item-title>
                  {{theNoob.fullName}} {{!theNoob.ageGroup ? '' : theNoob.ageGroup > 18 ? '' : `${theNoob.ageGroup}U`}}
                  <span class="grey--text text--lighten-1 caption">
                    {{theNoob.email ? ` - ${theNoob.email}` : ''}}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle>{{theNoob.cityState}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

          </v-list>
          <v-row dense justify="center">
            <v-col cols="12" class="title">
              What would you like to do?
            </v-col>
            <v-col cols="12">
              <v-btn
                color="color3 color3Text--text"
                @click.stop="cancelNumber"
                block
                class="blockBtn"
              >Enter a new number</v-btn>
            </v-col>
            <v-col cols="12" v-if="theNoob">
              <v-btn
                color="color3 color3Text--text"
                @click.stop="switchToNoob"
                block
                class="blockBtn"
              >Register with {{theNoob.fullName}}</v-btn>
            </v-col>
            <v-col cols="12" v-if="false">
              <v-btn
                color="color3Text color3--text"
                @click.stop="dialog = false"
                block
              >They are the same person</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
import { namesAreSame } from '@/classes/HelperFunctions'
import Logos from '@/classes/LogoUrls'
import { mapGetters } from 'vuex'
import AVP from '@/classes/AvpSanctioning'

export default {
  props: ['body', 'player', 'playerString', 'chipColor', 'minLevel', 'tournament'],
  data () {
    return {
      isOpen: false,
      dirty: false,
      loading: false,
      window: 0,
      dobPicker: false,
      history: {},
      lastResult: null,
      dialog: false,
      manualError: null
    }
  },
  computed: {
    ...mapGetters(['user']),
    sb () {
      return this.body && this.body.toLowerCase()
    },
    number: {
      get () {
        return this.player[this.prop]
      },
      set (v) {
        this.player[this.prop] = v
      }
    },
    prop () {
      return this.helpers.props[this.sb]
    },
    status: {
      get () {
        return this.player[this.helpers.statusProps[this.sb]]
      },
      set (v) {
        this.player[this.helpers.statusProps[this.sb]] = v
      }
    },
    helpers () {
      return {
        logos: {
          p1440: Logos.p1440,
          avpa: Logos.avpa,
          usav: Logos.usav
        },
        props: {
          p1440: 'p1440Number',
          avpa: 'avpNumber',
          usav: 'usavNumber'
        },
        statusProps: {
          p1440: 'p1440Status',
          avpa: 'avpaStatus',
          usav: 'usavStatus'
        },
        lookup: {
          p1440: 'https://play.p1440.com/member/search',
          avpa: 'https://avpamerica.com/LoginPlayerInitial.aspx',
          usav: 'https://usavolleyball.org/membership/'
        },
        lookupLabel: {
          p1440: 'p1440 Membership Look Up',
          avpa: 'Avp America Membership Look Up',
          usav: 'Become a USAV Member'
        },
        hint: {
          p1440: 'p1440 numbers start with P followed by a 5 digit number',
          avpa: 'AVP America Numbers are typically 7 or 9 digit numbers that start with 1',
          usav: false
        }
      }
    },
    valid () {
      return this.status === 'true'
    },
    actionText () {
      return this.valid ? 'Done' : 'Verify'
    },
    verifiable () {
      return this.number && this.player.dob
    },
    asOf () {
      return this.tournament && this.tournament.strict ? this.tournament.startDate.format('MM-DD-YYYY') : null
    },
    nameMatchWarn () {
      return this.player && this.lastResult && this.player.firstName && this.lastResult.firstName && this.player.lastName && this.lastResult.lastName && !(namesAreSame(this.lastResult.firstName, this.player.firstName) && namesAreSame(this.lastResult.lastName, this.player.lastName))
    },
    diffProfile () {
      return this.nameMatchWarnObj && this.nameMatchWarnObj.pId > 0 && this.nameMatchWarnObj.lId > 0 && this.nameMatchWarnObj.pId !== this.nameMatchWarnObj.lId
    },
    nameMatchWarnObj () {
      return {
        p: this.player,
        l: this.lastResult,
        a: this.player && this.lastResult && namesAreSame(this.lastResult.firstName, this.player.firstName),
        a2: this.lastResult && this.lastResult.firstName,
        a3: this.player && this.player.firstName,
        b: this.player && this.lastResult && namesAreSame(this.lastResult.lastName, this.player.lastName),
        b2: this.lastResult && this.lastResult.lastName,
        b3: this.player && this.player.lastName,
        pId: this.player ? this.player.playerProfileId : 0,
        lId: this.lastResult && this.lastResult.profile ? this.lastResult.profile.id : 0
      }
    },
    warn () {
      return this.player && this.player.playerProfileId && this.lastResult && this.lastResult.profile && this.lastResult.profile.id !== this.player.playerProfileId
    },
    theOg () {
      return this.player && this.player.searchResult
    },
    theNoob () {
      return this.lastResult && (this.lastResult.profile || {
        id: 0,
        firstName: this.lastResult.firstName,
        lastName: this.lastResult.lastName,
        fullName: `${this.lastResult.firstName} ${this.lastResult.lastName}`
      })
    },
    strict () {
      return this.tournament && this.tournament.strict
    },
    dto () {
      return {
        id: this.number,
        sanctioningBody: this.sb,
        asOf: this.asOf,
        dob: this.player.dob,
        firstname: this.player.firstName,
        lastName: this.player.lastName,
        minLevel: this.minLevel
      }
    }
  },
  methods: {
    open () {
      this.dirty = false
      this.isOpen = true
      // this.$nextTick(() => {
      //   this.$refs.numberInput.focus()
      // })
    },
    cancel () {
      this.number = null
      this.isOpen = false
    },
    override () {
      this.number = 0
      this.$nextTick(() => {
        this.status = 'true'
      })
      this.isOpen = false
    },
    onActionClick () {
      if (this.valid) {
        this.isOpen = false
      } else {
        if (!this.verifiable) return
        this.loading = true
        this.$VBL.verifyMe(this.dto)
          .then(r => { this.setResult(r.data) })
          .catch(e => console.log(e.response))
          .finally(() => { this.loading = false })
      }
    },
    setResult (dto) {
      this.manualError = null
      this.lastResult = dto
      if (this.minLevel) {
        this.levelCheck(dto)
      }
      this.$set(this.history, this.number, dto.status)
      this.status = dto.status
      if (this.status !== 'false') {
        if (this.diffProfile) {
          this.dialog = true
        } else {
          if (!this.player.playerProfileId) {
            if (dto.profile) {
              this.switchToNoob()
            } else {
              if (dto.firstName && !this.player.isOnFile('firstName')) {
                this.player.firstName = dto.firstName
                this.player.addOnFile('firstName')
              }
              if (dto.lastName && !this.player.isOnFile('lastName')) {
                this.player.lastName = dto.lastName
                this.player.addOnFile('lastName')
              }
            }
          }
          if (this.status === 'true') {
            this.$nextTick(() => {
              this.loading = false
              this.isOpen = false
            })
          }
        }
      }
    },
    levelCheck (dto) {
      if (this.sb === 'avpa') {
        const l = AVP.memberships.find(f => f.value === dto.level.toUpperCase()) || { n: -1 }
        const t = AVP.memberships.find(f => f.value === this.minLevel.toUpperCase())
        if (l.n < t.n) {
          dto.status = 'false'
          this.manualError = t.e
        }
      }
    },
    cancelNumber () {
      delete this.history[this.number]
      this.number = null
      this.status = null
      this.dialog = false
    },
    switchToNoob () {
      const n = this.number
      const d = this.player.dob
      this.player.setProfile(this.theNoob)
      this.player.dob = d
      if (!this.theNoob.profile) {
        this.number = n
        this.status = this.history[n]
        this.$emit('show-manual')
      }
      this.dialog = false
    }
  },
  watch: {
    number: function (n, o) {
      if (n) {
        this.status = this.history[this.number]
      } else {
        this.status = null
      }
    },
    dobPicker: function (val) {
      val && this.$nextTick(() => (this.$refs.dobPickerPicker.activePicker = 'YEAR'))
    }
  }
}
</script>
